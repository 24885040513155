<div class=" margins-top">
    <div class="row"></div>
    <div class="row row-size ">
            <div class="col 5 m5 s12 first-half-margins">
                <div class="card grey lighten-5 ">
                    <div class="card-content  ">
                      <span class="card-title bold"> sell or Buy</span>
                      <p>Whether you are looking for the latest laptop, or want to sell that piece of art that's in your living room.Market deals is an open market for all your needs</p>
                    </div>
                    <div class="card-action second-border-raduis">
                     <div class="row"> 
                       <a routerLink="market" class="col waves-effect waves-light btn blue">Trade Now</a>
                     
                      </div>
                    </div>
                  </div>
            </div>
      </div>
      
      <div class="row-margins row  ">
              <div class="col 12 m12 s12 card">
                  <div class="  grey lighten-5">
                      <div class="card-content  ">
                        <span class="card-title bold">Simple shop and product management</span>
                        <p>Create digital shops or manage your existing shops</p>
                      </div>
                      <div class="card-action">
                          <div class="row"> 
                              <!--<img src="assets/images/visa.jpg" style="width: 16em;">-->
                          </div>
                         </div>
                  </div>
              </div>
             
        </div>
        
        <div class="row second-row-margins">
          <div class="card grey lighten-5">
            <div class="card-content  ">
              <span class="card-title bold"></span>
              <p>Quick and Easy sign up Join our Beta program</p>
              
            </div>
            <div class="card-action"> <button routerLink="signUp" class="green btn" style="color:white">   Get Started</button></div>
        </div>
        </div>
        <div class="row">

          <div class="col 6 m6 s12">
    
         </div>
         <div class="col 6 m6 s12">
          
      </div>
      
</div>
<div class="row  medium-margin" style="height:15em">
  <div class="col s12 m12 12  background-banner3">
    <div class="row ">
      <div class="col offset-4 offset-s3 offset-m4    ">   
        <span class=""><a href="https://www.instagram.com/marketdeals.co.za/" style="color: white;">instagram </a></span> 
     
        <span routerLink="terms"   style="color:white">   terms and conditions </span>
     
      </div>


    </div>
  </div>
</div>