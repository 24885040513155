<div class="container">	 
    <div *ngIf="loading" > <h1 class="loader" ></h1>
        <li>loading search results</li>
    </div>
    <div class="shop-grid-wrapper">
        <div class="col ">
            <div *ngFor="let item of _shopsFirstRow;let i = index"  class="row">           
				<app-product [product]=item ></app-product>
            </div> 
        </div>
        <div class="col secondCol">
            <div *ngFor="let item of _shopsSecondRow;let i = index"  class="row">            
                
					<app-product [product]=item ></app-product>
            </div> 
        </div>	
    </div>  
</div>