<div class="container" (click)="close()">
    <div class='failure-banner'>
      <div class="row">

        <div class="col offset-2 offset-m2 offset-s2 text-style">
          <h4 class="failure-message">An error has occured : {{response}}  Click to Close</h4> 
       </div>
        
      </div>
    </div>
</div>  