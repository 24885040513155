import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrivateKeyInput } from 'crypto';
import { observable, Observable } from 'rxjs';
import { CartService } from '../cart.service';
import { Product } from '../models/product';
import { ProductService } from '../Services/product.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  
   public showNoItems: boolean =true;
 
   public showCheckout: boolean = false;
   public productsArray ;
   public intialLoad: boolean= true;
   public total:number;
  
  constructor(private cartService: CartService, private router:Router) {
    
    this.getTotal();
   }

   

  ngOnInit(): void 
  {
    this.cartBehaviour();
    this.productsArray = [] ;
    this.cartService.productsObservable?.subscribe(
      ()=>{
            this.updateCart();
       }); 
  }


  public getTotal()
  {
    this.total = this.cartService.getTotal();
  }

   public updateCart()
   {
     
    this.productsArray = this.cartService.getProducts();
    this.getTotal();
    if(this.productsArray!=null)
    {
      this.cartBehaviour();
    }
   }

   public cartBehaviour()
   {
    if(this.productsArray?.length==0)
    {
      this.showNoItems = true;
      this.showCheckout = false;

    }else
    {

      this.showCheckout = true;
      this.showNoItems = false;

    }
   }


   public cartBehaviourEmitter(event)
   {

    if(this.productsArray?.length==0)
    {
      this.showNoItems = true;
      this.showCheckout = false;

    }else
    {

      this.showCheckout = true;
      this.showNoItems = false;

    }
   }
  public updateProduct (product:Product[])
  {
 
    if(this.intialLoad ==false)
    {
      this.productsArray=product;
    }
    this.intialLoad=false;
  }


  public checkout()
  {
    this.router.navigate(['/Checkout']); 
  }
}



