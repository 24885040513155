import { Component, OnInit } from '@angular/core';
import { FormWizardService} from '../form-wizard.service';
import { FormControl } from '@angular/forms';
import { ProfileService } from '../Services/profile.service';
import { Profile } from '../models/profile';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.css']
})
export class SignUpFormComponent implements OnInit {

  public firstStep:  Boolean ;
  public secondStep: Boolean ;
  public thirdStep: Boolean ;
  public failedRequest :any;
  public successfulRequest :any;
  public firstName = new FormControl('');
  public  lastName = new FormControl('');
  public  phoneNumber = new FormControl ('');
  public  province = new  FormControl ('');
  public  city = new  FormControl ('');
  public  surburb = new FormControl('');
  public  streetName = new  FormControl ('');
  public  BuildingName = new FormControl('');
  public  unitNumber = new FormControl('');
  public  email = new FormControl('');
  public  password = new FormControl('');
  public  responseMessage;
  constructor(private formWizardService: FormWizardService , private profileService: ProfileService ,private authService: AuthService ) {
  
  }

  ngOnInit() {
    this.updateValues ();
    console.log(this.failedRequest + " this is the failed request property")
    
  }

  updateValues (): void {
    this.formWizardService.showFirststep.subscribe(x => this.firstStep = x);
    this.formWizardService.showSecondStep.subscribe(x => this.secondStep = x);
    this.formWizardService.showThirdStep.subscribe(x => this.thirdStep = x);
  }

  createNewUser (): void {
    const self= this;
    const profile  = new Profile();
    profile.Name = this.firstName.value + ' '  + this.lastName.value;
    profile.Password = this.password.value;
    profile.Email = this.email.value;
    this.authService.signup( profile,function(result){
      if(result.susscess){
        

        self.successfulRequest = true;
        self.responseMessage = "user password and email saved Successfully";
      }else{
        self.failedRequest = true;
        self.responseMessage =result.response ;
      }

    });
  }

  closeResponseBanner(evt){
    this.failedRequest= false;
  }


}
