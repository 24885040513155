import { Component, OnInit, Self } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/Services/profile.service';
import { PersonalProfile } from 'src/app/models/personal-profile';
import { Image } from 'src/app/models/image';
import { AuthService } from 'src/app/auth.service';
import { auth } from 'firebase';
import { AngularFireStorage   } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable, observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {   AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import {AngularFireObject}  from '@angular/fire/database';
import { Product } from 'src/app/models/product';

import { ProductService } from 'src/app/Services/product.service';
import { Shop } from 'src/app/models/shop';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent implements OnInit {

  public profile :any;
  public edit = false;
  successfulRequest: boolean;
  ref: any;
  uploadProgress: any =0;
  task: any;
  downloadURL: any;
  imageName : any;
  uploadURL: Observable <any> = new Observable<any>();
  showProgressBar :boolean =false;
  public  Name = new FormControl ('');
  public  phoneNumber = new FormControl ('');
  public  province = new  FormControl ('');
  public  city = new  FormControl (''); 
  public  surburb = new FormControl('');
  public  streetName = new  FormControl ('');
  public  BuildingName = new FormControl('');
  public  unitNumber = new FormControl('');
  public  _stotrage  : AngularFireStorage ;
  public _firestore : AngularFirestore  ;
  public _profileService :ProfileService;
  public _product :Product = new Product();
  private itemsCollection: AngularFirestoreCollection<object>;
  public Item: Observable<object[]>;
  public showProducts =false;
  public showShops =false;
  public getShops =false;
  public _productService: ProductService;
  public AllProducts :Array <Product> =  [];
  public AllShops :Array <Shop> =  [];
  public profileImageaAvailable: boolean;
  public showDefaultImage: boolean;

  constructor(public profileService: ProfileService , public productService:ProductService,   private authService: AuthService ,private storage: AngularFireStorage , private fireStore : AngularFirestore)  {
    this._profileService = profileService;
    this._stotrage =storage;
    this._productService =productService;
    this._firestore = fireStore;
    this.profile=    this._profileService.profile$;
    this.AllProducts = new Array<Product>();
    this.callInitilize();
   }

  ngOnInit() {
 
    this.callInitilize();
  }

  callInitilize()
  {
    const self = this;  
    self.profileService.profile$.subscribe( {next(_profile) {
      self.updateProfileDisplay(_profile);
    }});
    
    self.InitializePersonalProfile(function(profile){
       self.getDownloadUrlForImage(profile,function(profileWithURl){       
         self._profileService.updateProfileService(profileWithURl);
       })    
    })

  }

  saveProfileChanges (){
    const self = this;
    let _profile  = this.profile;
    _profile.Name =this.Name.value
    _profile.phoneNumber =this.phoneNumber.value;
    self._profileService.updateProfileService(_profile );
    self.authService.updateUser( _profile, function(result){
    } );
  }

  updateProfileDisplay(_profile){
    const self = this;
    self.profile = _profile;
  }

  showAllProducts(){
    var self = this;
    self.edit = false;
    
    if(self.showProducts){
      self.showProducts =false;
    }else{
    
      self.showProducts =true;
    }
    var _itemsCollection =  self._firestore.collection<Product>('posts', ref => ref.where('UID', '==',self.profile.profileID)).valueChanges();
          
      _itemsCollection.subscribe({next(x) 
      {
        self.AllProducts =x;
      }
      });
   
  }



  showAllShops()
  {
   
    var self = this;
    self.edit = false;
    
    if(self.showShops){
      self.showShops =false;
    }else{
    
      self.showShops =true;
    }
    var _itemsCollection =  self._firestore.collection<Shop>('shops', ref => ref.where('UID', '==',self.profile.profileID)).valueChanges();

      _itemsCollection.subscribe({next(x) 
        {
 
          self.AllShops =x; 
        }
      });
  }


  updatePersonalDetailsForUserProfile(profile)
  {
    
  }

  InitializePersonalProfile(done) {

      var self =this;

      this.authService.user.subscribe({
        next(user) {

          let _profile  = new Profile();
          
          _profile.profileID =user.uid;
          _profile.Name =user.providerData[0].displayName;
          _profile.image = new Image() ;
          _profile.image.ImageUrl = user.providerData[0].photoURL ;
          console.log(user)
          if(_profile.image.ImageUrl==null){
            self.profileImageaAvailable =false;
            self.showDefaultImage =true;
          }else{
            self.profileImageaAvailable=true;
            self.showDefaultImage =false;
          }


          //  self.getPost(_profile).subscribe( => console.log(">>>>>>>>>>>>>>>post",x.payload.val() ))
 
  
          return  done(_profile) ;
   
        } 
      });
    
     
  }

  public updateUserProfile(){
    let _profile  = new Profile();
    const self  = this;
    _profile.Name = this.profile.Name ||  " ";
    _profile.image = new Image();
    _profile.image.ImageUrl = this.uploadURL;
    _profile.image.ImageName = this.imageName;
    self._profileService.updateProfileService(_profile ); 
   
    this.authService.updateUser( _profile, function(result){
      self.profileImageaAvailable=true;
      self.showDefaultImage =false;
    } );
  }

  public  editProfile()  {

    this.showProducts =false;

    if (this.edit) {
      this.edit = false;
    } else {
      this.edit = true;
    }
  }

  public getDownloadUrlForImage(profile : Profile,done)
  {
   
    this.ref = this.storage.ref(profile.image.ImageUrl);
    let imageUrl = this.ref.getDownloadURL();


    imageUrl.subscribe({next(url)
    {
      profile.image.ImageUrl =url;
      return done(profile);

    }});
   
  }


  public getDownloadUrlForImages(image ,done)
  {
    this.ref = this.storage.ref(image);
    let imageUrl = this.ref.getDownloadURL();
    imageUrl.subscribe({next(url)
    {
      return done(url);
    }});
   
  }


  public  upload(event) {
    const randomId = Math.random().toString(36).substring(2);
    this.showProgressBar = true;
    this.ref = this.storage.ref(randomId);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges() ;
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.updateImageUrl(this.ref.getDownloadURL(),randomId);
      } )

    ).subscribe() 
    
    }
  
   public updateImageUrl(Observableobject,imageRef){
    const self = this;
    this.uploadURL= Observableobject;
    this.imageName = imageRef;
   
    this.uploadURL.subscribe({next (url) {
      let _profile  = new Profile();
      _profile.image = new Image() ;
      _profile.image.ImageUrl = url
      _profile.image.ImageName = imageRef;

      self._profileService.updateProfileService(_profile);
    }});
   }


  public  logout()  {
    this.authService.logout();
    this.successfulRequest = true;
  }

  public getPost(_profile){

      return  this._firestore.collection("posts" ,ref => ref.where('UID', '==', _profile.profileID)).snapshotChanges();
   
  } 

  public getShop(_profile){
    return  this._firestore.collection("shops" ,ref => ref.where('UID', '==', _profile.profileID)).snapshotChanges();
 
} 


  public getPosts(){
     return  this._firestore.collection('posts').snapshotChanges();
  }
 
}
