import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Shop } from '../models/shop';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {

  public loading:boolean = true;

  private _shops : Array<Shop> = new Array();
  public _shopsFirstRow : Array<Shop>;
  public _shopsSecondRow : Array<Shop>;
  myParam: string;

  constructor(private route: ActivatedRoute) { 
    this.route.params.subscribe(x => this.initialize(x))
   

  }


  ngOnInit(): void {
  }

  
  initialize(x){

    console.log(x["text"] ,"text")
    const Typesense = require('typesense')
    const client = new Typesense.Client({
      'nodes': [{'host': '5f1b2spc9imw3hr6p-1.a1.typesense.net', 'port': '443', 'protocol': 'https'}],
      'apiKey': 'mEx0zGJeIISphpKwxviHH1rY3wtEctfp',
      'connectionTimeoutSeconds': 5
    });
    
    client.collections('posts').documents().search({
      'query_by': 'description,Title',
      'q':  "phone"
    }).then(x=> this.loadingData(x))
  }
  
   
  
  loadingData(data)
  {
    var shop = new Shop();
   var filteredData =data['hits'];
    filteredData.forEach(element => {
       shop =element["document"];
      
      this._shops.push(shop)
    });
 
    console.log(this._shops)
    this.CreateGrid(this._shops);
    this.loading = false;


  }


  private CreateGrid(ObjectArray)
  {
    const half = Math.ceil(ObjectArray.length / 2);    
    this._shopsFirstRow  = ObjectArray.splice(0, half);
    console.log(this._shopsFirstRow)
    this._shopsSecondRow = ObjectArray.splice(-half);
  }
  
  
  public isDivisbleBy3(index :number)
  {
        if (index % 3 == 0)
        {
           return true;
        }else
        {
          return false;
        }
  }

  public isDivisbleBy2(index :number)
  {
        if (index % 2 == 0)
        {
           return true;
        }else
        {
          return false;
        }
  }

}
