import { Injectable } from '@angular/core';
import { Profile } from '../models/profile';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
 

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Authorization': 'my-auth-token'
  })
};
@Injectable({
  providedIn: 'root'
})

export class ProfileService {
 

  private profile: BehaviorSubject <Profile> = new BehaviorSubject (new Profile());
  public profile$ = this.profile.asObservable();
  private baseUrl = 'http://localhost:24241/api/';
  constructor(private http: HttpClient) { }

  updateProfileService(updatedProfile: Profile) {
    this.profile.next(updatedProfile);
  }
  registerNewUser (profile: Profile): Observable<string> {
    return this.http.post<string>(this.baseUrl + 'UserAccount', profile, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }




}
