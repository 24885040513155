<div class="container">
	<div *ngIf="loading" > 
		<div class="row">
			<div class="col offset-s4 offset-m4 offset-5 ">
				<h1 class="loader" ></h1>
        		<h5 class="loader-text">loading listings...</h5>
			</div>
		</div>
		
    </div>
	<div class="container">

			
	</div>
	<div class="market-place-margins row">
		<div class="col s12 m4 4 offset-1 offset-m1 ">
			<div *ngFor="let item of productsRow1;let i = index"  class="row">       			
					<app-product [product]=item ></app-product>
			</div> 
		</div>
		<div class="col s12 m4 4  offset-2 offset-s0 offset-m2  ">
			<div *ngFor="let item of productsRow2;let i = index"  class="row">       			
					<app-product [product]=item ></app-product>
			</div> 
		</div>	
	</div>
</div>

