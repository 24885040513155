import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-failure-message',
  templateUrl: './failure-message.component.html',
  styleUrls: ['./failure-message.component.css']
})
export class FailureMessageComponent implements OnInit {
  @Input() response;
  @Output() closeWindow = new EventEmitter();
  constructor() { }
  
  ngOnInit() {
  }

  close() {
    console.log("click event triggered");
    this.closeWindow.emit(true);
}
}
