import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-body',
  templateUrl: './landing-page-body.component.html',
  styleUrls: ['./landing-page-body.component.css']
})
export class LandingPageBodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
