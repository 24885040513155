
<div class="order-margins container">
    <div class="inner-content">
        <div class="col  s12 m5 5 offset-s0 offset-5 offset-m5 card ">
            <div class="card-content">
                <div class="card-title offset-m5"> My order
                </div>

                <div *ngFor="let item of _products;let i=index" class="col">       
                    <div class="row">
                        <div class="col  s12 m12 12 offset-s1 offset-1 offset-m1">
                                <div class="title">
                                    <span class="heading-word-wrap"> {{item.Title}} </span> 
                                </div>
                        </div>
                    </div>
                    <div  class="row">
                        <div class="col s6 m6 6 offset-s1 offset-m1 offset-1 ">
                                <span> PRICE  {{item.currencySymbol }}  {{item.price }}  </span> 
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col  s11 m11 11 offset-s1 offset-1 offset-m1">
                                <span > {{item.description}}  </span> 
                        </div>
                    </div>
                </div>
                <span>Total : {{_total}} </span>  
          
            
                <h5>Delivery Address</h5>
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="email" type="text" class="validate" placeholder="Zip Code " [formControl]="zipCode"> 
                </div>
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="email" type="text" class="validate" placeholder="Province " [formControl]="province"> 
                </div>
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="email" type="text" class="validate" placeholder="city " [formControl]="city"> 
                </div>
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="email" type="text" class="validate" placeholder="street Number" [formControl]="streeNumber"> 
                </div>
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="email" type="text" class="validate" placeholder="street Name" [formControl]="streetName"> 
                </div>
                
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="email" type="email" class="validate" placeholder="email address " [formControl]="email"> 
                </div>
            
            
                <div class="input-field offset-1 offset-m2  offset-s2 col s12 m5 5">
                    <input id="phone number" type="number" class="validate" placeholder="number" [formControl]="number"> 
                </div>
            
            </div>
        
            <div class="card-actions">
                <span class="btn right green" >Complete Order</span>
            </div>
        </div>
    </div>
</div>

