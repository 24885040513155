import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../models/product';
import { ProductService } from '../Services/product.service';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  public _product;

  constructor(private productService: ProductService, private sanitizer:DomSanitizer , private shoppingCartService:CartService) {
    
    
  }
  
  @Input() product: Product ;
  @Input() editableProduct: Boolean ;
  @Output() cartProduct = new EventEmitter<Product>();

  public image1: any;
  public image2: any;
  public image3: any;
  public isEditable:Boolean= false;
  public showDeleteConfirmation:Boolean=false;
  public showAddToCart:Boolean = true;
  public CartConfirmation:Boolean = false;

  ngOnInit() {
    var self = this;
    self._product = new Product();
    self._product = self.product; 
    self.initializeProduct();
    self.isEditable =  self.editableProduct?.valueOf(); 

    if(self.isEditable !=true){
      this.showAddToCart =true;
    }
  }

  initializeProduct () {
    this.image1 = this.formatURl(this._product.images[0]);
    this.image2 = this.formatURl(this._product.images[1]);
    this.image3 = this.formatURl(this._product.images[2]);
  }
  

  
  AddToCart()
  {
      this.shoppingCartService.addProduct(this._product);
      this.CartConfirmation =true;
      this.cartProduct.emit();  
  }


    
  hideCardConfirmation()
  {
      this.CartConfirmation =false;
      
  }

  loadPreview(productPreviewObject) {
    this.productService.updateProduct(productPreviewObject);
  }
  
  ConfirmDelete(){
      this.showDeleteConfirmation = false;
      this.productService.deleteProduct(this._product);
  }

  showDeleteMessage(){
    if(this.showDeleteConfirmation){
     
      this.showDeleteConfirmation = false;    
    }else
    {
      this.showDeleteConfirmation = true;
    }
   
  }
  
  changeImageOnDisplay(imageNumber) 
  {  
    let tempImage  = this.image1;
    let tempImage2 = this.image2;
    let tempImage3 = this.image3;

    switch (imageNumber) 
    {
 
      case 1:
        tempImage  = this.image1;
        tempImage2 = this.image2;
        tempImage3 = this.image3;

        this.image3 = tempImage3;
        this.image2 =tempImage;
        this.image1 = tempImage2
      case 2:
         
        tempImage  = this.image1;
        tempImage2 = this.image2;
        tempImage3 = this.image3;

        this.image3 = tempImage2;
        this.image2 =tempImage;
        this.image1 = tempImage3;

  
      default:
        break;
    }
     
  }

  public formatURl(url: string):SafeUrl {

    return  this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
