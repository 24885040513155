import { Image } from './image';
import { NumberValueAccessor } from '@angular/forms';

export class Profile {
    profileID: string;
    Name: string;
    image: Image;
    profileType: string ;
    AddressId: string ;
    verrified: boolean;
    Password: string ;
    Email: string;
    phoneNumber: number;
}

