import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MetarialDesignModule } from './metarial-design/metarial-design.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LandingPageBodyComponent } from './landing-page-body/landing-page-body.component';
import { FooterComponent } from './footer/footer.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { ProductComponent } from './product/product.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { CreateProductComponent } from './create-product/create-product.component';

import { AngularFileUploaderModule } from 'angular-file-uploader';
import { ReactiveFormsModule } from '@angular/forms';
import {FormsModule} from '@angular/forms';
import { ProfilePageComponent } from './user-profile/profile-page/profile-page.component';
import { HttpClientModule } from '@angular/common/http';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AuthService } from './auth.service';
import { FailureMessageComponent } from './failure-message/failure-message.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import {AngularFireStorageModule, StorageBucket} from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreModule } from 'angularfire2/firestore';
import { ProfileService } from './Services/profile.service';
import { ProductFeedComponent } from './product-feed/product-feed.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ShopComponent } from './shop/shop.component';
import { ShopsGridComponent } from './shops-grid/shops-grid.component';
import { ApiService } from './api.service';
import { ShopsService } from './shops.service';
import { CreateShopComponent } from './create-shop/create-shop.component';
import { ShopProductComponent } from './shop-product/shop-product.component';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { MatIconModule } from '@angular/material/icon';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CartProductComponent } from './cart-product/cart-product.component';
import { OrdersComponent } from './orders/orders.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingPageBodyComponent,
    FooterComponent,
    SideNavComponent,
    ProfilePageComponent,
    LoginFormComponent,
    SignUpFormComponent,
    ProductComponent,
    MarketPlaceComponent,
    CreateProductComponent,
    SuccessMessageComponent,
    FailureMessageComponent,
    ProductFeedComponent,
    SearchBarComponent,
    ShopComponent,
    ShopsGridComponent,
    CreateShopComponent,
    ShopProductComponent,
    TermsAndConditionsComponent,
    SearchResultsComponent,
    ShoppingCartComponent,
    CartProductComponent,
    OrdersComponent
  ],
  imports: [
    BrowserModule,
    
    AppRoutingModule,
    MatIconModule,
    MetarialDesignModule,
    CommonModule,
    AngularFirestoreModule ,
    AngularFileUploaderModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule 
  ],
  providers: [AuthService ,ApiService,ShopsService , ProfileService , AngularFireAuthGuard ,{ provide: StorageBucket, useValue: 'bluepastels-cfa0d.appspot.com' }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
