<div>
    <div class="shop-wrapper card">
            <div class="row ">
                <div class="col  s3 m3 3 offset-s1 offset-1 offset-m1">
                        <div class="title"> <h3>Shop Name  : </h3>   </div>
                </div>
                   
                <div class="col s3 m3 3 offset-s1 offset-m1 offset-1 ">
                        <span>    <h3>   {{_shop.shopName}} </h3>  </span> 
                </div>
            </div>
           
            <div  class="row">
                    <div class="col s3 m3 3 offset-s1 offset-m1 offset-1 ">
                            <span> Location :      </span> 
                    </div>
                    <div class="col s3 m3 3 offset-s1 offset-m1 offset-1 ">
                        <span>     {{_shop.Location }}    </span> 
                  </div>
            </div>  
            <div  class="row">
                <div class="col s3 m3 3 offset-s1 offset-m1 offset-1 ">
                        <span> Description :      </span> 
                </div>
                <div class="col s3 m3 3 offset-s1 offset-m1 offset-1 ">
                    <span>     {{_shop.Description }}    </span> 
              </div>
          </div>
        
            <div class="row">
                 
                <div class="col s6 m6 6 offset-s1 offset-m1 offset-1 ">
                        <button class="btn blue " >    <a  href="/shop?shopId={{_shop.shopId}}" style="color:white">visit shop</a>   </button>
                </div>   
            </div>
            <div class="row"> 
           
            </div>
    </div>
</div>  