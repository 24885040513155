<div class="cart">
    <div class="shop-content">
        
        <p>Shopping Cart</p>
       
           <div *ngIf="showNoItems">
            <ul>
                <li >Cart Empty : Add Items to your cart </li> 
            </ul>
            
           </div>
            <div *ngIf="showCheckout">  
                <ul>
                    <li> <h5>Total : {{total}}</h5></li>
                </ul>
                <ul>
                    <div *ngFor="let item of productsArray;let i = index"  class="row">            
                        <app-cart-product [product]=item ></app-cart-product >
                     
                    </div> 
                    <li  class="btn right green" (click)="checkout()" > Checkout </li>

                </ul>   
            </div>
        
    </div>
</div>


