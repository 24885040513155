<div class="container">
    <div  class="card grey lighten-3">
        <div class="">
            <div class="row profile-margin-top profile-botton-padding">
                <div class="col s3 3 m3 offset-s1 offset-m1 offset-1">
                      Name : {{profile.Name}}
                </div>
            </div>
            <div class="row">
                <div class="col s3 3 m3 offset-s1 offset-m1 offset-1">
                    <div *ngIf="showDefaultImage">
                        <img class="circle" src="assets/images/user.png"  alt="defualt image not found"   width="100px">
                   </div>   
                </div> 
                <div class="col s3 3 m3 offset-s1 offset-m1 offset-1">
                <div *ngIf="profileImageaAvailable">
                    <img class="circle" src="{{profile.image.ImageUrl}}" alt="image not found"   width="100px">
               </div>
            </div> 
             
            </div>
            <div class="row">
                <div class="col s5 5 m5 offset-s1 offset-m1 offset-1">
                Location  : {{profile.Location}}
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                  <ul class="tabs">
                    <li class="tab col s1 pointer-cursor"  (click)="editProfile()">Edit </li>
                    <li class="tab col s1 pointer-cursor"  (click)="showAllProducts()">  Products </li>
                    <li class="tab col s1 pointer-cursor"  (click)="showAllShops()"> Shops </li>
                    <li class="tab col s1 pointer-cursor"  (click)="logout()"> logout</li>
                  </ul>
                </div>
            
            </div>
          
        </div>
    </div>
    <div class="products-controls" *ngIf="showProducts;">
        <a class="waves-effect waves-light btn blue product-button" routerLink='/CreateProduct'><i class="material-icons left">+</i>add product</a> 
    </div>
    <div class="products-controls" *ngIf="showAllShops;">
        <a class="waves-effect waves-light btn blue product-button" routerLink='/CreateShop'><i class="material-icons left">+</i>add shop</a> 
    </div>
    <div class="products" *ngIf="showProducts;">  
        <div *ngFor="let item of AllProducts;let i = index" class="col">       
            <app-product [product]=item editableProduct="true"></app-product>
        </div>        
    </div>
    <div class="products" *ngIf="showShops;">  
        <div *ngFor="let item of AllShops;let i = index" class="col">       
           <app-shop [shop]=item ></app-shop>
        </div>        
    </div>
            
    </div>
    <div *ngIf="edit;">
        <div class="row container">
            <div class="col s12 m5 5 card">
                    <div class="row "  *ngIf="showProgressBar">
                        <span for="profilePicture" class="profile-picture-edit-label" >profile Picture</span>
                        <progress class="progress-bar-margin-top" max="100" [value]="(uploadProgress | async)"></progress>
                    
                    </div>
                    <div class="row">
                            <div id="profilePicture" class="input-field imageInputMargin btn pink "   >
                                    <input  #file   max="1" min="1" type="file" accept='image/*'   (change)="upload($event)"  />
                                    upload
                            </div>
                    </div>
                    <div class="row">
                            <div class="result" *ngIf="uploadURL | async; let uploadSrc">
                                    <button class="btn small green lighten imageInputMargin left" (click)="updateUserProfile()">save profile picture</button>
                            </div>
                    </div>
                    <div class="row offset-1 offset-s1 offset-m1  ">
                        <div><span class="card-title">Personal Details</span></div>
                          <div class="input-field col s12 m12 12 ">
                            <input id="firstName" type="text" class="validate" placeholder="Name : {{profile.Name}}" minlength="3" value="{{profile.Name}}" [formControl]="Name"> 
                          </div>
                        </div>
                      
                        <div class="row offset-1 offset-s1 offset-m1">
                            <div class="input-field col s12 m12 12  ">
                                <input id="PhoneNumber" type="text" class="validate" placeholder="Phone Number : {{profile.PhoneNumber}}" minlength="3"  value="{{profile.PhoneNumber}}" [formControl]="phoneNumber"> 
                        </div>
                    </div>
                    <div class="row offset-1 offset-s1 offset-m1  ">
                         
                            <div class="row offset-1 offset-s1 offset-m1">
                                <div class="input-field col s12 m4 4   ">
                                        <button class="btn small green lighten left bottom-button-margin" (click)="saveProfileChanges()">Save profile Settings</button>
                            </div>
                    </div>    
            </div>
        </div>
    </div>
</div>

<div *ngIf="successfulRequest">
        <app-success-message ></app-success-message>
 </div>