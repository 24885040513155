import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { LandingPageBodyComponent } from './landing-page-body/landing-page-body.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { ProductComponent } from './product/product.component';
import {CreateProductComponent } from './create-product/create-product.component';
import { ProfilePageComponent } from './user-profile/profile-page/profile-page.component';
import { AngularFireAuthGuard,  redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { ShopsGridComponent } from './shops-grid/shops-grid.component';
import { CreateShopComponent } from './create-shop/create-shop.component';
import { ShopProductComponent } from './shop-product/shop-product.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { OrdersComponent } from './orders/orders.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);

const routes: Routes = [
  { path: 'CreateProduct' , component: CreateProductComponent,canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'CreateShop' , component: CreateShopComponent,canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'product' , component: ProductComponent},
  { path: 'market' , component: MarketPlaceComponent},
  { path: 'Checkout' , component: OrdersComponent},
  { path: 'shop' , component: ShopProductComponent},
  { path: 'shops' , component: ShopsGridComponent },
  { path: 'login', component: LoginFormComponent },
  { path: 'search/:text', component: SearchResultsComponent },
  { path: 'terms', component: TermsAndConditionsComponent },
  { path: 'profile', component: ProfilePageComponent ,canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'signUp', component: SignUpFormComponent },
  { path: '', component: LandingPageBodyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
