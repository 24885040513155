import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
 
import { Shop } from '../models/shop';
import {ShopsService } from '../shops.service'
@Component({
  selector: 'app-shops-grid',
  templateUrl: './shops-grid.component.html',
  styleUrls: ['./shops-grid.component.css']
})
export class ShopsGridComponent implements OnInit {

  private _shopService:ShopsService;
  private _shops : Array<Shop>;
  public _shopsFirstRow : Array<Shop>;
  public _shopsSecondRow : Array<Shop>;
  public loading : boolean = true;

  constructor( shopservice: ShopsService ) {
    this._shopService = shopservice;
  }
  
  
   
  
  ngOnInit() {

    this.getAllShops();
    console.log(this._shops);
  
  }
  
  getAllShops(){
    
    var shops=  this._shopService.getShopData();
    shops.subscribe( shopData => this.loadingData(shopData));

  }
  
  loadingData(data){

    this._shops = data;
    console.log("DATA>>>>>>>>>>>>>>>>>>,",data);
    this.CreateGrid(this._shops);
    this.loading = false;


  }


  private CreateGrid(ObjectArray)
  {
    const half = Math.ceil(ObjectArray.length / 2);    
    this._shopsFirstRow  = ObjectArray.splice(0, half);
    this._shopsSecondRow = ObjectArray.splice(-half);
  }
  
  
  public isDivisbleBy3(index :number)
  {
        if (index % 3 == 0)
        {
           return true;
        }else
        {
          return false;
        }
  }

  public isDivisbleBy2(index :number)
  {
        if (index % 2 == 0)
        {
           return true;
        }else
        {
          return false;
        }
  }
}
