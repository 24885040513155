
<div *ngIf="showLoginContainer"> 
<div class="container">
    <div class="row login-margins">
      <form class="col s10 6 m6 container card offset-m3 offset-3 offset-s1">
        <div class="row">
            <div class="container"><span class="card-title">sign in credentials</span></div>
          <div class="input-field col s12">
            <input id="email" type="email" class="validate" placeholder="email address " [formControl]="email"> 
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
              <input id="password" type="password" class="validate" placeholder="password" [formControl]="password"> 
          </div>
        </div>  
        <div class="card-action">
          <div class="row"> 
            <button  class="col waves-effect waves-light btn blue right"  (click)="login()" >sign in</button>
            <a href="signUp "        class="left  btn green ">   Register </a>
          </div>
          <div class="divider"></div>
          <div class="row">
            <button (click)="forgortPasswordAction()"   class=" waves-effect waves-light green   left" style="color: white;"> Reset Password </button>
          </div>
        </div>
      </form>
    </div>
</div>
</div>
<div *ngIf="forgortPassword">
  <div class="container">
    <div class="row login-margins">
      <form class="col s10 6 m6 container card offset-m3 offset-3 offset-s1">
        <div class="row">
            <div class="container"><span class="card-title">Reset Password</span></div>
          <div class="input-field col s12">
            <input id="email" type="email" class="validate" placeholder="email address " [formControl]="email"> 
          </div>
        </div>      
        <div class="card-action">
          <div class="row"> 
            <button  class="col waves-effect waves-light btn orange left"  (click)="forgortPasswordAction()" >back</button>
            <button  class="col waves-effect waves-light btn green right"  (click)="resetPassword()" >Reset</button>
           </div>
           <div class="divider"></div>
         </div>
      </form>
    </div>
    </div>
  
</div>

<div *ngIf="passwordResetSuccess">
  <app-success-message  redirectPath="" [response]="responseMessage"></app-success-message>
</div>
<div *ngIf="failedRequest"> 
  <app-failure-message [response]='responseMessage' (closeWindow)="closeResponseBanner($event)"></app-failure-message>>
</div>
<div *ngIf="successfulRequest">
    <app-success-message  redirectPath="profile"></app-success-message>
  </div>
  <div *ngIf="failedRequest"> 
    <app-failure-message [response]='responseMessage' (closeWindow)="closeResponseBanner($event)"></app-failure-message>>
</div>