import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from './models/product';

@Injectable({
  providedIn: 'root'
})
export class CartService {


  products: BehaviorSubject <Product>;
  productsObservable: any;
  productsArray: Product[];
  intialLoad:boolean;
  productsSession;

  constructor()
  {
      this.products = new BehaviorSubject(new Product());
      this.productsObservable =this.products.asObservable();
      this.productsArray =[];
  }


  removeProduct(_product: Product) {

      const index = this.productsArray.indexOf(_product);
      // only splice array when item is found
        this.productsArray.splice(index, 1); // 2nd parameter means remove one item only
      

      localStorage.setItem('ArrayOfProducts',JSON.stringify(this.productsArray));
      this.getTotal();
  }

  addProduct(updatedProductObject: Product)
   {
    this.products.next(updatedProductObject);
    this.updateSession(updatedProductObject);
   }


   public getProducts()
   {

    const products = localStorage.getItem('ArrayOfProducts');
    this.productsArray = JSON.parse(products);

 
     return this.productsArray;
   }

   public getTotal()
   {
    var total=0;
    this.productsArray.forEach(product => {
      
          total+= product.price;
    }); 

    return total;
   }

   updateSession(product:Product)
   {
     

    if(this.productsArray==null)
    {
        this.productsArray = [];

    } 

    if(this.productsSession!=null)
    {
   
      var objects = JSON.parse(this.productsSession);
 

    }
 
    this.productsArray.push(product);
   
    if(this.productsArray.length>0)
    {
      localStorage.setItem('ArrayOfProducts',JSON.stringify(this.productsArray));

    }

   }
}
