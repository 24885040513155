import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
//      import { NEXT } from '@angular/core/src/render3/interfaces/view';

@Injectable({
  providedIn: 'root'
})

export class FormWizardService {

  private showFirstStepSubject:  BehaviorSubject <Boolean> = new BehaviorSubject (true);
  private showSecondStepSubject:  BehaviorSubject <Boolean> = new BehaviorSubject (false);
  private showThirdStepSubject:  BehaviorSubject <Boolean> = new BehaviorSubject (false);
  showFirststep = this.showFirstStepSubject.asObservable();
  showSecondStep = this.showSecondStepSubject.asObservable();
  showThirdStep = this.showThirdStepSubject.asObservable();

  constructor() { }

  SetFirstStep(): void {
    this.showSecondStepSubject.next(false);
    this.showThirdStepSubject.next(false);
    this.showFirstStepSubject.next(true);
  }

  SetSecondStep (): void {
    this.showFirstStepSubject.next(false);
    this.showThirdStepSubject.next(false);
    this.showSecondStepSubject.next(true);
  }

  SetThirdStep(): void {
    this.showSecondStepSubject.next(false);
    this.showFirstStepSubject.next(false);
    this.showThirdStepSubject.next(true);
  }
}
