<div class="container">	 
    <div *ngIf="loading" > <h1 class="loader" ></h1>
        <li>loading...</li>
    </div>
    <div class="shop-grid-wrapper">
        <div class="col ">
            <div *ngFor="let item of _shopsFirstRow;let i = index"  class="row">           
                <app-shop [shop]=item ></app-shop> 
                    
            </div> 
        </div>
        <div class="col secondCol">
            <div *ngFor="let item of _shopsSecondRow;let i = index"  class="row">            
                    <app-shop [shop]=item ></app-shop>
                 
            </div> 
        </div>	
    </div>  
</div>
