import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import {IMyDpOptions} from 'mydatepicker';
import {FormWizardService} from '../form-wizard.service';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { ImageUploadService } from '../image-upload.service';
import { Observable } from 'rxjs';
import { Image } from '../models/image';
import { FormControl, FormBuilder } from '@angular/forms';
import { ProductService } from '../Services/product.service';
import { Product } from '../models/product';
import { ProfileService } from '../Services/profile.service';
import { Profile } from '../models/profile';
import { Shop } from '../models/shop';
import { ShopsService } from '../shops.service';

@Component({
  selector: 'app-create-product',
  
  templateUrl: './create-shop.component.html',
  styleUrls: ['./create-shop.component.css']
})

export class CreateShopComponent implements OnInit {

  public successfulRequest: Boolean;
  public responseMessage: string;
  public _profile: Profile;
  public _profileService: ProfileService;
  public model: any = { date: { year: 2018, month: 10, day: 9 } };
  public firstStep: Boolean;
  public secondStep: Boolean;
  public thirdStep: Boolean;
  public image1: Image;
  public image2: Image;
  public image3: Image;
  public product: Product;
  public shop: Shop;
  public profileID: string;
  public productName = new FormControl('');
  public description = new FormControl('');
  public location = new FormControl('');
  public currentPrice = new FormControl('');
  public originalPrice = new FormControl('');
  public dateCreated = new FormControl('');
  public used = new FormControl('');
  images: Image[];
  public files: any;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };

  constructor(public formWizardService: FormWizardService, public imageUploadService: ImageUploadService,
    public shopService: ShopsService, public formBuilder: FormBuilder, public profileService: ProfileService) {
  
      this.product = new Product();
      this.shop = new Shop();
      this._profile = new Profile();
      this.updateValues();
      this.updateImageValues();
      this.imageUploadService.initializeImages();
    }
    
    ngOnInit() {
      var self = this;
       this.profileService.profile$.subscribe({next(x)
        {
          self.profileID = x.profileID;
          
        }
      });
  }

   processImages(files): void {
    this.imageUploadService.updateImages(files);
   }

  updateImageValues ():  void {
    this.imageUploadService.image1$.subscribe(x => this.image1 = x);
    this.imageUploadService.image2$.subscribe(x => this.image2 = x);
    this.imageUploadService.image3$.subscribe(x => this.image3 = x);

  }

  updateValues (): void {
    this.formWizardService.showFirststep.subscribe(x => this.firstStep = x);
    this.formWizardService.showSecondStep.subscribe(x => this.secondStep = x);
    this.formWizardService.showThirdStep.subscribe(x => this.thirdStep = x);
  }

  productPreview(): void {
    const  images: Image [] = [];
    images.push(this.image1.ImageUrl);
    images.push(this.image2.ImageUrl);
    images.push(this.image3.ImageUrl);

    this.shop.UID = this.profileID;
    this.shop.shopId = Math.floor(Math.random() * 20) +this.profileID; 
    this.shop.shopName = this.productName.value;
    this.shop.Location = this.location.value;
    this.shop.Description = this.description.value;
    //this.shop.price = this.currentPrice.value;


   // this.product.originalPrice = this.originalPrice.value;
   // this.product.DateCreated = this.dateCreated.value;
    
  
    //this.shopService.(this.product);
  }


  saveProduct(): void {
    const self = this;
    this.shopService.createShopById(JSON.parse(JSON.stringify(this.shop))).then(function (response) { 

    
 
      self.successfulRequest = true;
      self.responseMessage = "product added successfully";

    }).catch(function (e) { 

 
      self.successfulRequest = false;
      self.responseMessage = e.message;

    })

  }

}

