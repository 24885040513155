import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Profile } from '../models/profile';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  public  email = new FormControl('');
  public  password = new FormControl('');
  failedRequest: boolean;
  successfulRequest: boolean;
  responseMessage: string;
  forgortPassword:boolean;
  showLoginContainer:boolean;
  passwordResetSuccess:boolean;
  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.showLoginContainer =true;
    this.forgortPassword = false;
    this.passwordResetSuccess =false;
  }

  
  login() {
    
    const self= this;
    const profile  = new Profile();

    profile.Email = this.email.value;
    profile.Password = this.password.value;
 
    this.authService.login(profile.Email, profile.Password, function(result){
      if (result.susscess) {
        self.successfulRequest = true;
        self.responseMessage = "user registered Successfully";
      }else{
        self.failedRequest = true;
        self.responseMessage =result.response ;
      }

    });
  }

  closeResponseBanner(evt){
    this.failedRequest= false;
  }

  forgortPasswordAction()
  {
     if(this.showLoginContainer ){
      this.showLoginContainer =false;
      this.forgortPassword =true;

     }else{
      this.showLoginContainer =true;
      this.forgortPassword =false;

     }
    
  }

  resetPassword()
  {
    const self= this;
    this.authService.resetUserPassword(self.email.value, function(result){
      if (result.susscess) {
        self.passwordResetSuccess = true;
        self.responseMessage = "User Password reset sent to " + self.email.value;
      }else{
        self.failedRequest = true;
        self.responseMessage =result.response ;
      }

    });

  }

}
