import { Injectable } from '@angular/core';
import { Shop} from './models/shop'
import { ApiService } from './api.service'
import { AngularFirestore} from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class ShopsService {

private _shop: Shop;
private ApiService: ApiService;
  
  constructor(private afs: AngularFirestore) { 
    this._shop = new Shop();
    this.ApiService =new ApiService();
    this.ApiService.intilizeApiService(afs,"shops");
  }
  
  public getShopData():any  {
 
    return this.ApiService.returnAllItems();
  }
  
  public getShopsByUserId(id: string):any{
    return this.ApiService.returnByQuery("UID=="+id);
  }

  public getShopDataByShopID(id: string):any{
    return this.ApiService.returnByQueryID("shopId",id);
  }

  public DeleteShopDataByShopID(id: string):any{
    return this.ApiService.removeByQueryID("shopId",id);
  }


  public  createShopById(shop :Shop):any{
    return this.ApiService.saveObjectToDB(shop);
  }

}
