<form class="form-top-margins">
<div *ngIf="firstStep;">
    <div class="container">
            <div class="row">
               <div class="progress">
                    <div class="determinate" style="width: 33%"></div>
                </div>
              <div class="col s10 6 m6 container card offset-m3 offset-3 offset-s1">
                <div class="row">
                    <div class="container"><span class="card-title">Personal Details</span></div>
                  <div class="input-field col s12">
                    <input id="firstName" type="text" class="validate" placeholder="First name" minlength="3" [formControl]="firstName"> 
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s12">
                      <input id="lastName" type="text" class="validate" placeholder="Last name" minlength="3" [formControl]="lastName"> 
                  </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <input id="PhoneNumber" type="text" class="validate" placeholder="Phone Number" minlength="3" [formControl]="phoneNumber"> 
                    </div>
                </div>
                <div class="card-action">
                  <div class="row"> 
                      <button class="col waves-effect waves-light btn blue" (click)="formWizardService.SetSecondStep(); updateValues()" type="button">Next</button >
                      <button class="right waves-effect waves-light btn red"   type="button">cancel</button > 
                   </div>
                 </div>
                </div>
            </div>
    </div>
  </div>
<div  *ngIf="secondStep;">
    <div class="container">
            <div class="row">
                <div class="progress">
                    <div class="determinate" style="width:66%"></div>
                </div>
              <div class="col s10 6 m6 container card offset-m3 offset-3 offset-s1">
                  <div class="row">
                      <div class="container"><span class="card-title">Delivery/pick-up address</span></div>
                    <div class="input-field col s12">
                      <input id="province" type="text" class="validate" placeholder="province" [formControl]="province"> 
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col s12">
                        <input id="city/town" type="text" class="validate" placeholder="city/town" [formControl]="city"> 
                    </div>
                  </div>
                  <div class="row">
                      <div class="input-field col s12">
                          <input id="suburb" type="text" class="validate" placeholder="suburb" [formControl]="surburb"> 
                      </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <input id="streetName" type="text" class="validate" placeholder="street Name" [formControl]="streetName"> 
                        </div>
                      </div>
                      <div class="row">
                          <div class="input-field col s12">
                              <input id="buildingName" type="text" class="validate" placeholder="Building Name" [formControl]="BuildingName"> 
                          </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <input id="unitNumber" type="text" class="validate" placeholder="Unit Number" [formControl]="unitNumber"> 
                            </div>
                          </div>
                <div class="card-action">
                  <div class="row"> 
                    <button class="col waves-effect waves-light btn blue" (click)="formWizardService.SetThirdStep(); updateValues()">Next</button >
                   <button class="right waves-effect waves-light btn yellow darken-4" (click)="formWizardService.SetFirstStep(); updateValues()" type="button">back</button >
                   </div>
                 </div>
                </div>
            </div>
    </div>
  </div>
<div *ngIf="thirdStep;">
    <div class="container">
        <div class="row">
            <div class="progress">
                <div class="determinate" style="width:99%"></div>
            </div>
          <div class="col s10 6 m6 container card offset-m3 offset-3 offset-s1">
            <div class="row">
                <div class="container"><span class="card-title">sign in credentials</span></div>
              <div class="input-field col s12">
                <input id="email" type="email" class="validate" placeholder="email address " [formControl]="email"> 
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12">
                  <input id="password" type="password" class="validate" placeholder="password" [formControl]="password"> 
              </div>
            </div>
            <div class="card-action">
              <div class="row"> 
                <button class="col waves-effect waves-light btn blue"  type="button" type="button" (click)="createNewUser()">Finish</button >
               <button  class="right waves-effect waves-light btn yellow darken-4" (click)="formWizardService.SetSecondStep()"  type="button">back</button >
               </div>
             </div>
          </div>
        </div>
    </div>
  </div>
</form>

<div *ngIf="successfulRequest">
  <app-success-message ></app-success-message>
</div>
<div *ngIf="failedRequest"> 
  <app-failure-message [response]='responseMessage' (closeWindow)="closeResponseBanner($event)"></app-failure-message>>
</div>