export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBPac1-bHssq2hoAG0ghQ5-ZuwXWDoMJN4",
    authDomain: "bluepastels-cfa0d.firebaseapp.com",
    databaseURL: "https://bluepastels-cfa0d.firebaseio.com",
    projectId: "bluepastels-cfa0d",
    storageBucket: "",
    messagingSenderId: "1020913600726",
    appId: "1:1020913600726:web:91e44b21faa107c541a177",
    measurementId: "G-W0T8CFGYFW"
 
  }
};
