import { Component, Input, OnInit } from '@angular/core';
import { Shop } from '../models/shop';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  constructor() { }
  @Input() shop: Shop ;
  public _shop:Shop;
  ngOnInit() {

    this._shop= this.shop;
  }

}
