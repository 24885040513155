import { Image } from './image';

export class Product {
        id: string;
        ProductID:string;
        Available: boolean
        images:any  []
        Title: string
        UID: string
        currencySymbol: string
        dateCreated: ""
        price: number
        shopId: string
        description: string
        
}
