import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Product } from '../models/product';
import { Profile } from '../models/profile';
import { Shop } from '../models/shop';
import { ProductService } from '../Services/product.service';
import { ShopsService } from '../shops.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-shop-product',
  templateUrl: './shop-product.component.html',
  styleUrls: ['./shop-product.component.css']
})
export class ShopProductComponent implements OnInit {
 
  public  shop:Shop;
  public  product:Product [];
  public  _productService:ProductService;
  public  _shopservice:ShopsService;
  public  profileID:string;
  private _authService: any;
  public showProducts:Boolean;
  private route: ActivatedRoute;
  constructor(public productService:ProductService,public shopservice: ShopsService,  private authService: AuthService ,private activatedRoute:ActivatedRoute) 
  {
    
      this.route =activatedRoute;
      this._productService = productService;
      this._shopservice = shopservice;
      this._authService =authService;
      this.InitializePersonalProfile();

  }

  ngOnInit() {
   
    const shopID = this.route.snapshot.queryParams.shopId;
    this.LoadShop(shopID);
    this.LoadProducts(shopID); 
    
  }

  public LoadShop(shopID:string)
  {
    this.shop = new Shop();
 
    console.log("loading");
    this.shopservice.getShopDataByShopID(shopID).subscribe((shopData) => {
       
       this.shop.Location = shopData[0].Location;
       this.shop.shopName= shopData[0].Location;
       this.shop.Description= shopData[0].Description;
       this.shop.Category = shopData[0].category;
       this.shop.shopId =shopData[0].shopId;
       

      });

  }


  public LoadProducts(ShopID:string)
  {
    this._productService.returnByQueryID("shopId",ShopID).subscribe((x)=>
    {
          
      this.mapProducts(x);
       
    });
  }

  public mapProducts(products:any){
    this.product = [];
    
    products.forEach(element => {
      
      var product = new Product();
      product.id=element.id;
      product.Available=element.Available;
      product.images =element.images;
      product.Title =element.Title;
      product.UID=element.UID;
      product.currencySymbol =element.currencySymbol;
      product.dateCreated= element.dateCreated;
      product.price=  element.price;
      product.shopId= element.shopId;
      product.description=element.description;


      this.product.push(product);
    });
    this.showProducts =true;
    console.log(this.product)
  }



  InitializePersonalProfile() {

    var self =this;

    this.authService.user.subscribe({
      next(user) {

        self.profileID =user.uid;

 
      } 
    });
}




  public LoadShops()
  {
    this._shopservice.getShopsByUserId(this.profileID).subscribe((x)=>
    {
       this.shop = x;
    });
  }

}
