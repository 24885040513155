import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { BehaviorSubject, Observable, observable } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
   
   private itemsCollection: AngularFirestoreCollection<Product>;
   product: BehaviorSubject <Product> = new BehaviorSubject(new Product());
   product$ = this.product.asObservable();
   public _firestore : AngularFirestore  ;
   public AllProducts :Array <Product> =  [];
   public _tablname:string;
   constructor (private afs: AngularFirestore)
   {
      this._tablname ='posts'
      this.itemsCollection = afs.collection<Product>(this._tablname);
      //this.items = this.itemsCollection.valueChanges();
      this._firestore =afs;
      let defaultProduct = new Product();
      defaultProduct.Title="New Product";
      defaultProduct.images =["Insert Image","insert Image","InsertImage"] ;
   
      this.updateProduct(defaultProduct)
       
   }

   updateProduct(updatedProductObject: Product) {
      this.product.next(updatedProductObject);
   }

   public deleteProduct(productToDelete: Product) {
      console.log("deleting Product>>>>>>>>>>",productToDelete)
      this._firestore
      .collection(this._tablname, (ref) => ref.where('ProductID', '==', productToDelete.ProductID))
      .get()
      .subscribe((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              console.log('Document successfully deleted!');
            })
            .catch(function (error) {
              console.error('Error removing document: ', error);
            });
        })
      });
   }

   public returnByQueryID(variable: string,value:string) : Observable<any[]>
   {
     var self = this;
     return self._firestore.collection(this._tablname, ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      { query = query.where(variable, '==', value) };
    
      return query;
    }).valueChanges()
   
   }
   returnProduct(): Product {
      return new Product() ;
   }

   saveNewProductToDB(product: Product) {
      return this.itemsCollection.add(product)
   }

   public showAllProducts()
   {
      var self = this;
    
      var _itemsCollection =  self._firestore.collection<Product>('posts').valueChanges();
            
      _itemsCollection.subscribe(
      {
         next(x) 
         {
            self.AllProducts = x;
   
         }
       });    
       
       
    }

    public returnAllProducts () : Observable<Product[]>
    {
      var self = this;
    
      return self._firestore.collection<Product>('posts').valueChanges();
            
    }

}
