import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { Product } from '../models/product';

@Component({
  selector: 'app-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.css']
})
export class CartProductComponent implements OnInit {

  @Input() product: Product ;
  public _product:Product;
  private _cartService:CartService;
  constructor(private cartService: CartService) 
  {
    this._cartService= cartService;
  }

  ngOnInit(): void {
 
    this._product = this.product;
  }

  public Remove()
  {
    this._cartService.removeProduct(this._product);
    
  }

 

}
