<div class="container">
    <div  class="card grey lighten-3">
        <div class="">
            <div class="row profile-margin-top profile-botton-padding">
                <div class="col s3 3 m3 offset-s1 offset-m1 offset-1">
                    {{shop.shopName}}
                </div>
            </div>
            <div class="row">
                <div class="col s5 5 m5 offset-s1 offset-m1 offset-1">
                Location  : {{shop.Location}}
                </div>
            </div>
            <div class="row">
                <div class="col s5 5 m5 offset-s1 offset-m1 offset-1">
                 Desctiption  : {{shop.Description}}
                </div>
            </div>
            <div class="row">
                <div class="col s5 5 m5 offset-s1 offset-m1 offset-1">
                    category  : {{shop.Category}}
                </div>
            </div>

            
        </div>
    </div>
    <div class="products" *ngIf="showProducts;">  
            <div *ngFor="let item of product;let i = index" class="col">       
                <app-product [product]=item ></app-product>
            </div>        
    </div>
</div>
