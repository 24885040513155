<div>
        <div class="product-wrapper card">
                <div class="row ">
                        <div class="col  s12 m12 12 offset-s1 offset-1 offset-m1">
                                <div class="title"> <span class="heading-word-wrap"> {{_product.Title}} </span> </div>
                        </div>
                </div>
                <div class="row">
                        <div class="col s8 m8 8 offset-s2 offset-m2 offset-2 picture-border">
                                <img  [src]="image1" width="100%" height="100%"> 
                        </div>
                </div>
                <div  class="row">
                        <div class="col s6 m6 6 offset-s1 offset-m1 offset-1 ">
                                <span> PRICE  {{_product.currencySymbol }}  {{_product.price }}  </span> 
                        </div>
                </div>  
                <div class="row">
                        <div class="col  s11 m11 11 offset-s1 offset-1 offset-m1">
                                <span > {{_product.description}}  </span> 
                        </div>
                        
                </div>
              
                <div class="row"> 
                        <div class="card-content ">
                                <div class="row">
                                        <div class="col s3 m3 3  offset-2 offset-s2 offset-m2 picture-border product-image pointer-cursor" (click)="changeImageOnDisplay(1)">
                                                <img [src]="image2" width="100%" height="100%">    
                                        </div>
                                        <div class="col  s3 m3 3  offset-1 offset-s1 offset-m1 picture-border product-image pointer-cursor" (click)="changeImageOnDisplay(2)">
                                                <img [src]="image3"  width="100%" height="100%" > 
                                        </div>

                                </div>
                        </div>
                </div>

                <div class="edit-button card-action " *ngIf="isEditable">
                        <button class="btn yellow ">Edit</button>
                        <button class="btn red edit-margin" (click)="showDeleteMessage()">Delete</button>

                </div>
                <div class="edit-button card-action " *ngIf="showAddToCart">
                        <button class="btn red edit-margin" (click)="AddToCart()">Add to Cart</button>
                 
                        <button class="btn red edit-margin right" (click)="VisitShop()">Visit shop</button>
                </div>
        </div>
</div>  
<div class="delete-confirmation" *ngIf="showDeleteConfirmation">
        <div class="card">
                <div class="card-content">
                        Are You sure you want to Delete 

                </div>
               <div class="card-action">
                        <button class="btn yellow " (click)="showDeleteMessage()">cancel</button>
                        <button class="btn red edit-margin" (click)=" ConfirmDelete()">confirm</button>
               </div>
        </div>
</div>
<div class="delete-confirmation" *ngIf="CartConfirmation">
        <div class="card">
                <div class="card-content">
                        Added to Cart Successfully, click menu -> cart, to checkout

                </div>
               <div class="card-action">
                        <button class="btn green " (click)="hideCardConfirmation()">close</button>
                     
               </div>
        </div>
</div>