
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { query } from '@angular/animations';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private itemsCollection: AngularFirestoreCollection<any>;
  public _firestore : AngularFirestore;
  public allObjects :Array<any> =[];
  private _tableName: string
  
  constructor (  )
  {


  }


  public intilizeApiService(afs?: AngularFirestore,   tableName?:string){
   this._tableName=tableName;
   this.itemsCollection = afs.collection<any>(this._tableName);
   //this.items = this.itemsCollection.valueChanges();
   this._firestore =afs;
  }

  saveObjectToDB(itemToInsert: any) {
     return this.itemsCollection.add(itemToInsert)
  }

  public showAllItems(tableName:string)
  {
     var self = this;
     var _itemsCollection =  self._firestore.collection<any>(tableName).valueChanges();
           
     _itemsCollection.subscribe(
     {
        next(x) 
        {
           self.allObjects = x;
        }
      });
   }

   public returnAllItems() : Observable<any[]>
   {
     var self = this;
     return self._firestore.collection<any>(this._tableName).valueChanges();
   }

   public returnByQuery(query: string) : Observable<any[]>
   {
     var self = this;
     return self._firestore.collection<any>(this._tableName).valueChanges();
   }
   

   public returnByQueryID(variable: string,value:string) : Observable<any[]>
   {
     var self = this;
     return self._firestore.collection(this._tableName, ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      { query = query.where(variable, '==', value) };
    
      return query;
    }).valueChanges()
   
   }
   public removeByQueryID(variable: string,value:string) 
   {
     var self = this;
     var returnedValue =self._firestore.collection(this._tableName, ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      { query = query.where(variable, '==', value) };
    
      return query;
    }).ref.doc().delete();
   
   }
}
