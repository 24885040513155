 
     <nav class="blue darken-3 header top-header-position">
        <div class="nav-wrapper">
          <a routerLink="/" class="brand-logo left"><div class="logo-size"> </div> </a>
          <ul id="nav-mobile" class="right hide-on-large-only menu-position">  
            <div (click)="ActivateSideMenu()"> 
              menu
            </div>
          </ul>
      <!--   <ul>
            <li class="searchbar"> <app-search-bar></app-search-bar></li> 
          </ul>-->
          <ul id="nav-mobile" class="right hide-on-med-and-down margin-for-mobile">  
            <li class="li-size " (click)="ActivateShoppingCart()"> <i class="bi bi-basket2"> Cart</i>  </li>
            <li class="li-size"><a routerLink="market">  <i class="bi bi-star-fill"> Latest</i> </a></li>
            <li class="li-size"><a routerLink="shops"> <i class="bi bi-shop-window"> Market</i> </a></li>
            <li class="li-size" *ngIf="!isUserAuthenticated;"><a routerLink="login"> <i class="bi bi-door-open">Sign in</i></a></li>
            <li class="li-size" *ngIf="!isUserAuthenticated;"><a routerLink="signUp">  <i class="bi bi-pencil-square">Sign up</i></a></li>
            <li class="li-size" *ngIf="isUserAuthenticated;"> <a routerLink="profile">Profile</a></li>
          </ul>
        </div>
      </nav>
      <div *ngIf="showShoppingCart;"  class="card shopping-cart-dimensions">
       <div class="container">
        <div class="row"> 
          <button (click)="ActivateShoppingCart()" class="btn red right closeButton"> <i class="bi bi-x-lg"  > close</i> </button>  
            <div class="col s12 m12 12 menu-list-font listing-margins">    
               
              <app-shopping-cart (cartProduct) ="cartBehaviourEmitter($event)"></app-shopping-cart>
            </div>
          </div>
        </div>
      </div>
      
      <div *ngIf="showSideMenu;" class="card header-z-index">
      <div class="row"> 
          <div class="col s12 m12 12 menu-list-font listing-margins">        
            <div class="row listing-text-margins" (click)="ActivateShoppingCart()">
              Cart <i class="bi bi-basket2">   </i> 
            </div> 
            <div class="divider"></div>
              <div class="row listing-text-margins">
                <a routerLink="market">Latest 
                <i class="bi bi-star-fill"></i></a>
              </div> 
              <div class="divider"></div>
              <div class="row listing-text-margins">
                <a routerLink="shops">Market 
                <i class="bi bi-shop-window"></i></a>
              </div>   
              <div class="divider"></div>
              <div class="row listing-text-margins">
                <a *ngIf="!isUserAuthenticated;" routerLink="login">Sign in
                <i class="bi bi-door-open"></i></a>
              </div>

              <div class="divider"></div>
              <div class="row listing-text-margins">
                <a *ngIf="!isUserAuthenticated;" routerLink="signUp">Sign up
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              
              <div class="divider"></div>
                <div class="row listing-text-margins">
                    <a *ngIf="isUserAuthenticated;" routerLink="profile">Profile</a>
                </div>   
              <div class="divider"></div>
          </div>
      </div>
  </div>
  
 