
<div class="container">
  <form>
    <div class="row  ">
      <div class="card" *ngIf="hasShops">
        Please Add shops before adding product:
       <a class="waves-effect waves-light btn blue product-button" routerLink='/CreateShop' >add shop</a>
      </div>
      <div class="col s12 m6 4 offset-6 offest-m4 " *ngIf="hasShops">
       
          <div  *ngIf="firstStep;">
            <div  class="card " > 
              <div class="card-stacked">
                  <div class="row">
                    <div class="card-header col offset-3 offest-m3 offset-s1">
                          <h4>Add a new Product to your shop</h4>
                    </div>
                  </div>
                
                <div class="card-content">
                  <div class="row"> <div class="progress">
                        <div class="determinate" style="width: 33%"></div>
                    </div>
                    <div class="row"></div>
                    <div class="row input-field ">
                    
                       
                      <select name="shops" id="shops" style="display: block;" [formControl]="shopSelected" >
                        <option *ngIf="!shopsLoaded">Loading your shops please wait..</option>
                       <option *ngFor="let shop of shops;let i = index" ngValue="{{shop.shopId}}" >{{shop.shopName}}</option>
                      </select>
                    
                      
                    </div>  
                  <div class="row">
                    <div class="input-field offset-1 offset-s1 offest-m1">
                      <input  type="text" placeholder="Product Name" [formControl]="productName">
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field offset-1 offset-s1 offest-m1">
                      <input   type="number" type="text" placeholder="Origianl Price In Rands" [formControl]="originalPrice">
                    </div>
                  </div>
                
                  <div class="row">
                    <div class="input-field offset-1 offset-s1 offest-m1">
                      <input  type="number" class="validate" placeholder="Current Price In Rands" [formControl]="currentPrice">
                    </div>
                  </div>
                  <div class="row">
                      <div class="input-field offset-1 offset-s1 offest-m1">
                        <input  type="text" placeholder="description" [formControl]="description">
                      </div>
                    </div>
                  <div class="row">
                      <div class="offset-1 offset-s1 offest-m1">
                        <label for="product date-created"> <h6> Date Created</h6></label>
                      </div>
                    <div class="input-field   offset-1 offset-s1 offest-m1">
                      <my-date-picker name="mydate" [options]="myDatePickerOptions" [formControl]="dateCreated"
                      required id="date-created"></my-date-picker>
                    </div>  
                  </div> 
                </div>
                <div class="card-action">
                    <div  class="row">
                        <div class="col"> <button (click)="formWizardService.SetSecondStep ();" class="col waves-effect waves-light btn blue" type="button">Next</button> </div>
                        <div class="col offset-s1 offest-1 offest-m1"><button class="col waves-effect waves-light btn red" type="button">Cancel</button> </div>
                    </div> 
                
                </div>
              </div>
            </div>
          </div>
          </div>
          <div *ngIf="secondStep;">
              <div  class="card " > 
                  <div class="card-stacked">
                      <div class="row">
                          <div class="card-header col offset-3 offest-m3 offset-s1">
                              <img src="assets/images/product-banner.png" width="100%">
                          </div>
                      </div>
                    <div class="card-content">
                      <div class="row"> <div class="progress">
                            <div class="determinate" style="width: 45%"></div>
                        </div>
                        <div class="row" >
                          <div class="col s3 m3 3" >
                            <div class="card-image">
                              <img src="{{image1.ImageUrl}}"  height="50%"   width="100%">
                            </div> 
                        </div>
                          <div class="col s3 m3 3 offest-1 offest-m1 offset-s1" >
                            <div class="card-image">
                              <img src="{{image2.ImageUrl}}"  height="50%" width="100%">
                            </div>
                        </div>
                        <div class="col s3 m3 3 offest-1 offest-m1 offset-s1" >
                                  <div class="card-image">
                                    <img src="{{image3.ImageUrl}}"  height="50%" width="100%">
                                  </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col  s2 2 m2  ">
                                <input #file  class="btn pink " multiple max="3" min="3" type="file" accept='image/*' (change)="processImages(file.files)" />
                            </div>
                        </div>
                        
                      </div>
                    </div>  
                    <div class="card-action">
                        <div class="row">
                        <div class="col"><button (click)="formWizardService.SetFirstStep ()" class="col waves-effect waves-light btn  yellow darken-4" type="button">Back</button> </div>
                        <div class="col offset-s1 offest-1 offest-m1 "><button (click)="formWizardService.SetThirdStep (); productPreview()" class="col waves-effect waves-dark btn  blue" type="button">Next</button> </div>
                        </div>
                    </div>
                  </div>
            </div>
          </div>
          <div *ngIf="thirdStep;">
              <div  class="card " > 
                  <div class="card-stacked">
                      <div class="row">
                          <div class="card-header col offset-3 offest-m3 offset-s1">
                              <img src="assets/images/product-banner.png" width="100%">
                          </div>
                      </div>
                      <div class="card-content">
                        <div class="row"> <div class="progress">
                            <div class="determinate" style="width: 99%"></div>
                        </div>
                        <app-product [product]=product></app-product>
                      </div>
                      <div class="card-action">
                        <div  class="row">
                            <div class="col"><button (click)="formWizardService.SetSecondStep (); updateValues ()" class="col waves-effect waves-light btn yellow darken-4" type="button">Back</button> </div>
                            <div class="col offset-s1 offest-1 offest-m1"><button (click)="saveProduct(); updateValues ()" class="col waves-effect waves-light btn Green" type="button">Done</button> </div>
                        </div>  
                      </div>
                  </div>

            </div>
          </div>
          </div>
      </div>
   </div>
  </form>
    <div *ngIf="successfulRequest">
      <app-success-message  redirectPath="profile"></app-success-message>
    </div>
</div>
