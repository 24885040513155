import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../models/product';
import { ProductService } from 'src/app/Services/product.service';

@Component({
  selector: 'app-market-place',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.css']
})
export class MarketPlaceComponent implements OnInit {

  public productsRow1: Array <Product> ;
  public productsRow2: Array <Product> ;
  public _productService: ProductService;
  public loading : boolean;
  constructor(public productService:ProductService)
  {
    this._productService =productService;
  }
  
  
  ngOnInit() {
  
    this.loading = true;
    this.LoadProducts();
    
  }
  
  public LoadProducts()
  {
    this._productService.returnAllProducts().subscribe((x)=>
    {
      this.loading = false;
  
      this.CreateGrid(x)
    });
  }
  
  public CreateGrid(ObjectArray)
  {
   
    const half = Math.ceil(ObjectArray.length / 2);    
    this.productsRow1  = ObjectArray.splice(0, half);
    this.productsRow2= ObjectArray.splice(-half);
  }
  
  
  public isDivisbleBy3(index :number)
  {
        if (index % 3 == 0)
        {
           return true;
        }else
        {
          return false;
        }
  }

  public isDivisbleBy2(index :number)
  {
        if (index % 2 == 0)
        {
           return true;
        }else
        {
          return false;
        }
  }
}
