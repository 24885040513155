import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.css']
})
export class SuccessMessageComponent implements OnInit {
  
  @Input() message: string;
  // this property value is bound to a different property name
  // when this component is instantiated in a template.
  @Input() redirectPath: string;
  constructor() { }

  ngOnInit() {
  }

}
