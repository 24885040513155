import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Profile } from './models/profile';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<firebase.User>;
  public IsUserAuthendticated: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

  constructor(private firebaseAuth: AngularFireAuth) { 
    this.user = firebaseAuth.authState;
  }
  signup (userProfile: Profile  , done :Function) :any 
  {
    this.firebaseAuth
      .auth
      .createUserWithEmailAndPassword(userProfile.Email.toString(), userProfile.Password)
      .then(value =>  {

        let result = {"susscess" : true, "response": value}
      
        this.updateUser(userProfile  , function(result)
        { 
         
        
        });

        return done(result)

      })
      .catch(err => {

        let result = {"susscess" : false, "response": err};

        return done(result)

      });    
  }

  login(email: string, password: string , done :Function) {
    this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value =>  {

        let result = {"susscess" : true, "response": value}
        this.IsUserAuthendticated.next(true);
        return done(result)

      })
      .catch(err => {
        let result = {"susscess" : false, "response": err}
        this.IsUserAuthendticated.next(false);
       
        return done(result)
      });
  }


  
  signInWithGoogle(email: string, password: string , done :Function) {
    this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(value =>  {

        let result = {"susscess" : true, "response": value}
        this.IsUserAuthendticated.next(true);
        return done(result)

      })
      .catch(err => {
        let result = {"susscess" : false, "response": err}
        this.IsUserAuthendticated.next(true);
       
        return done(result)
      });
  }

  updateUser(profile: Profile  , done :Function) {
        return this.saveUpdateUserChanges(profile, done)
  }

  saveUpdateUserChanges(profile :Profile, done :Function)
  {
    var user = this.firebaseAuth.user;

    user.subscribe({next(userInfo) 
    {
       userInfo.updateProfile({
          displayName:   profile.Name,
          photoURL: profile.image.ImageName
        });

       return done(user)
    }});
    
  }

  resetUserPassword(email:string, done:Function){

    var user = this.firebaseAuth.user;
    this.firebaseAuth
    .auth
    .sendPasswordResetEmail( email)
    .then(value =>  {

      let result = {"susscess" : true, "response": value}
      this.IsUserAuthendticated.next(true);
      return done(result)

    })
    .catch(err => {
      let result = {"susscess" : false, "response": err}
      this.IsUserAuthendticated.next(true);
     
      return done(result)
    });
   
  }

  logout() {
    this.firebaseAuth
      .auth
      .signOut();
  }
}
