import { Component, OnInit, Input, SimpleChange } from '@angular/core';
 
import {FormWizardService} from '../form-wizard.service';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { ImageUploadService } from '../image-upload.service';
import { Observable } from 'rxjs';
import { Image } from '../models/image';
import { FormControl, FormBuilder } from '@angular/forms';
import { ProductService } from '../Services/product.service';
import { Product } from '../models/product';
import { ProfileService } from '../Services/profile.service';
import { Profile } from '../models/profile';
import { ShopsService } from '../shops.service';
import { Shop } from '../models/shop';
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})

export class CreateProductComponent implements OnInit {

  public successfulRequest: Boolean;
  public hasShops:Boolean;
  public responseMessage: string;
  public _profile: Profile;
  public _profileService: ProfileService;
  public model: any = { date: { year: 2018, month: 10, day: 9 } };
  public firstStep: Boolean;
  public secondStep: Boolean;
  public thirdStep: Boolean;
  public image1: Image;
  public image2: Image;
  public image3: Image;
  public shops:  Shop[];
  public shopsLoaded :Boolean;
  public product: Product;
  public profileID: string;
  public productName = new FormControl('');
  public shopSelected = new FormControl('');
  public description = new FormControl('');
  public currentPrice = new FormControl('');
  public originalPrice = new FormControl('');
  public dateCreated = new FormControl('');
  public used = new FormControl('');
  images: Image[];
  public files: any;
  public _shopService:ShopsService;
 
  constructor(public formWizardService: FormWizardService, public imageUploadService: ImageUploadService,
    public productService: ProductService, public formBuilder: FormBuilder, public profileService: ProfileService
      ,public shopService: ShopsService) {
      this.shopsLoaded =false;
      this.product = new Product();
      this._profile = new Profile();
      this._shopService =shopService;
      this.getShops();
      this.updateValues();
      this.updateImageValues();
      this.imageUploadService.initializeImages();
    }
    
    ngOnInit() {
      var self = this;
       this.profileService.profile$.subscribe({next(x)
        {
          self.profileID = x.profileID;
          
        }
      });
      
  }

   processImages(files): void {
    this.imageUploadService.updateImages(files);
   }

  updateImageValues ():  void {
    this.imageUploadService.image1$.subscribe(x => this.image1 = x);
    this.imageUploadService.image2$.subscribe(x => this.image2 = x);
    this.imageUploadService.image3$.subscribe(x => this.image3 = x);

  }

  updateValues (): void {
    this.formWizardService.showFirststep.subscribe(x => this.firstStep = x);
    this.formWizardService.showSecondStep.subscribe(x => this.secondStep = x);
    this.formWizardService.showThirdStep.subscribe(x => this.thirdStep = x);
  }

  productPreview(): void {
    const  images: Image [] = [];
    images.push(this.image1.ImageUrl);
    images.push(this.image2.ImageUrl);
    images.push(this.image3.ImageUrl);

    this.product.UID = this.profileID;
    this.product.ProductID = this.createUUID();
    this.product.Title = this.productName.value;
    this.product.description = this.description.value;
    this.product.price = this.currentPrice.value;
    this.product.shopId = this.shopSelected.value;

    console.log(this.product);
   // this.product.originalPrice = this.originalPrice.value;
   // this.product.DateCreated = this.dateCreated.value;
    this.product.images = images;
  
    this.productService.updateProduct(this.product);
  }

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
       return v.toString(16);
    });
 }

  saveProduct(): void {
    const self = this;
    this.productService.saveNewProductToDB(JSON.parse(JSON.stringify(this.product))).then(function (response) { 

       
      self.successfulRequest = true;
      self.responseMessage = "product added successfully";

    }).catch(function (e) { 

      
      self.successfulRequest = false;
      self.responseMessage = e.message;

    })

  }


  getShops(): void{
    var self =this;
    console.log("start getShops");
    var shops = this._shopService.getShopsByUserId(this.profileID)
    shops.subscribe(
      {
         next(x) 
         {
            self.shops = x;
            self.shopsLoaded =true;
         }
       });
    this.shops = shops;
    
    if(shops!=null)
    {
      this.hasShops=true;
    }
    else
    {

      this.hasShops=false;
    }
  }

}
