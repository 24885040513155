import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, ObservableLike } from 'rxjs';
import { supportsScrollBehavior } from '@angular/cdk/platform';
import { filter, map } from 'rxjs/operators';
import { Image } from './models/image';
@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

   private image1: BehaviorSubject<Image> = new BehaviorSubject(new Image());
   private image2: BehaviorSubject<Image> = new BehaviorSubject(new Image()) ;
   private image3: BehaviorSubject<Image> = new BehaviorSubject(new Image()) ;

   image1$ =  this.image1.asObservable();
   image2$ =  this.image2.asObservable();
   image3$ =  this.image3.asObservable();

  constructor() {

  }

  initializeImages(): void {
    this.image1.next(this.loadDefaultImages(new Image()));
    this.image2.next(this.loadDefaultImages(new Image()));
    this.image3.next(this.loadDefaultImages(new Image()));
  }

  preview(files): Image {
    const image  = new Image();

    if (files.length === 0){
      return;
    }
    const mimeType = files.type;
    if (mimeType.match(/image\/*/) == null) {
        image.Message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
     image.ImagePath = files;
    reader.readAsDataURL(files);

    reader.onload = (_event) => {
      image.ImageUrl = reader.result;
    };
    return image;
  }

  updateImages(files) {
    this.image1.next(this.preview(files[0]));
    this.image2.next(this.preview(files[1]));
    this.image3.next(this.preview(files[2]));
  }

  returnObservalbe (SubjectArray):  Observable <any> {
    return SubjectArray.asObservable();
  }

  loadDefaultImages( image: Image): Image {
      image.ImageUrl = 'assets/images/place-holder.jpg';
      return image;
}
}
