import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  private _cartService;
  public _products : string;
  public _total: number;
  public number=new FormControl('');
  public email=new FormControl('');
  public streetName=new FormControl('');
  public streeNumber=new FormControl('');
  public city=new FormControl('');
  public province=new FormControl('');
  public zipCode=new FormControl('');

  constructor(cartservice: CartService) {

    this._cartService= cartservice;

   }

  ngOnInit(): void 
  {
     this.getProductData();
  }

  /**
   * name
   */
  public getProductData() {
    this._products = this._cartService.getProducts();
    this._total = this._cartService.getTotal();  
  }



 

}
