<div class="product-wrapper card">
    <div class="row ">
            <div class="col  s12 m12 12 ">
                    <div class="btn right red" (click)="Remove()">Remove </div>
                    <div class="title"> <span class="heading-word-wrap"> {{_product.Title}} </span> </div>
            </div>
    </div>
  
    <div  class="row">
            <div class="col s6 m6 6 offset-s1 offset-m1 offset-1 ">
                    <span> PRICE  {{_product.currencySymbol }}  {{_product.price }}  </span> 
            </div>
    </div>  
    <div class="row">
            <div class="col  s11 m11 11 offset-s1 offset-1 offset-m1">
                    <span > {{_product.description}}  </span> 
            </div>
            
    </div>
  </div>