<a routerLink='/{{redirectPath}}'>
<div class="container">
  <div class='success-banner'>
    <div class="row">
        <div class="col offset-4 offset-m4 offset-s4 text-style">
           <h3>success : click to continue</h3> 
        </div>
    </div>
  </div>  
</div>
</a>  