import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

   public showSideMenu: Boolean = false;
   public showShoppingCart: Boolean = false;
   private _authService :AuthService;
   public isUserAuthenticated: Boolean = false;
   
  constructor(authService: AuthService) 
  {
    this._authService = authService;
    this.ChcckIfUserIsAuthenticated();
  }

  ngOnInit() {

  }

  ActivateSideMenu(): void 
  {

    if (this.showSideMenu)
    {
      this.showSideMenu = false;
    } else 
    {
      this.showSideMenu = true;
    }
  }

 

  ActivateShoppingCart(): void {
   
    if (this.showShoppingCart) 
    {
      this.showShoppingCart = false;
     
      
    } else 
    {
      this.showSideMenu = false;
      this.showShoppingCart = true;

    }
  }
  public  logout()  {
    this._authService.logout();
     
  }

  ChcckIfUserIsAuthenticated():void{
 
    this._authService.IsUserAuthendticated.subscribe(authentication => this.isUserAuthenticated =authentication);
  }

}
